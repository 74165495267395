<template>
  <b-container fluid class="px-0 ">
    <b-row class="bg-info py-5 mx-0">
      <b-col class="px-0">
        <b-container>
          <b-row>
            <b-col cols lg="2" md="12" class="ml-auto text-center">
              <b-img :src="'logo_jl.png' | img" fluid class="brand-image"></b-img>
            </b-col>
            <b-col cols lg="6" class="my-auto mr-auto brand-text">
              <h1 class="text-white">Elevating IT for your business</h1>
              <hr>
              <h4 class="text-white text-muted">
                Take your IT to the next level.</h4>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="py-5 bg-secondary mx-0">
      <b-col class="px-0">
        <b-container>
          <b-row>
            <b-col cols md="12" class="text-left text-white my-2">
              <h1 class="">My Mission</h1>
              <hr />
              <p>
                It is my goal to accommodate you in the world of the latest technology and give you and your business the
                online presence you deserve.<br>
                I will ensure prominent public visibility for your brand, consult you in hardware related questions and
                am now also offering simple and concise solutions for your inventory management and accounting. All
                matters IT - I have got you covered.<br><br>
                I take care of all IT related aspects of your business so that you can focus on what you and your
                business know and do best.
              </p>
              <b-button variant="light" to="/contact">Get in contact</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-container class="py-5">
      <b-row>
        <b-col>
          <h1>Discover my Services</h1>
          <hr>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols lg="4" md="6" sm="12" class="mx-auto my-3">
          <b-card header-tag="header" footer-tag="footer">
            <template #header>
              <h4 class="my-2">Websites</h4>
            </template>
            <b-card-text>
              <b-icon icon="display" font-scale="5" class="mb-2 card-icon"> </b-icon>
              <h4 class="mb-3">Your online presence</h4>
              My goal is to give you a website which is tailored to your personal wishes and needs and which
              offers you the online presence your business deserves.
            </b-card-text>

            <template #footer>
              <b-button to="/websites" variant="primary">Learn more</b-button>
            </template>
          </b-card>
        </b-col>
        <b-col cols lg="4" md="6" sm="12" class="mx-auto my-3">
          <b-card header-tag="header" footer-tag="footer">
            <template #header>
              <h4 class="my-2">Software</h4>
            </template>
            <b-card-text>
              <b-icon icon="disc" font-scale="5" class="mb-2 card-icon"> </b-icon>
              <h4 class="mb-3">The right tool for the job</h4>

              I will make it my absolute priority to find the right software for you - to support your business and make
              your life easier.
            </b-card-text>

            <template #footer>
              <b-button to="/hosting" variant="primary">Learn more</b-button>
            </template>
          </b-card>
        </b-col>
        <b-col cols lg="4" md="6" sm="12" class="mx-auto my-3">
          <b-card header-tag="header" footer-tag="footer">
            <template #header>
              <h4 class="my-2">IT Infrastructure</h4>
            </template>
            <b-card-text>
              <b-icon icon="hdd-network" font-scale="5" class="mb-2 card-icon"> </b-icon>
              <h4 class="mb-3">Powerful and reliable</h4>
              My support goes beyond the web. I will set you up with solid WiFi and organised and thoughtful networking.
              I also provide networked storage solutions and custom end user workstations.
            </b-card-text>
            <template #footer>
              <b-button to="/infrastructure" variant="primary">Learn more</b-button>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "Homepage",
};
</script>

<style scoped>
.card-header {
  padding: 0;
  background-color: var(--primary);
  color: white;
}

.card-body {
  /* width: 200px; height: 100px; */
  background-size: 350% 100%;

  background-image: linear-gradient(-220deg, white 50%, #10202b 50%);

  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
  /* transition: color 1s; */
}

.card-body:hover {
  background-position: +100% 0;
  /* color: white; */
}

.card-icon {
  color: var(--info);
  transition: color 1s;
}

.card-body .card-text,
.card-title {
  color: black;
  transition: color 1s;
  -webkit-transition: color 1s;
  -moz-transition: color 1s;
}

.card-body:hover .card-text,
.card-body:hover .card-title,

.card-body:hover .card-icon {
  color: white;
  transition: color 1s;
  -webkit-transition: color 1s;
  -moz-transition: color 1s;
}


.card {
  height: 100%;
}

@media (max-width: 991px) {
  .brand-image {
    width: 150px;
    margin-bottom: 20px;
  }

}</style>