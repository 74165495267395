import Router from "vue-router";
import Vue from "vue";
import Homepage from "@/pages/Homepage.vue";
import Contact from "@/pages/Contact.vue";
import Hosting from "@/pages/services/Hosting.vue";
import Websites from "@/pages/services/Websites.vue";
import Infrastructure from "@/pages/services/Infrastructure.vue";

Vue.use(Router);

export default new Router({
    mode: "history",
    scrollBehavior() {
        return {y: 0}
    },
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: Homepage
        },
        {
            path: "/hosting",
            name: "hosting",
            component: Hosting
        },
        {
            path: "/websites",
            name: "websites",
            component: Websites
        },
        {
            path: "/infrastructure",
            name: "infrastructure",
            component: Infrastructure
        },
        {
            path: "/contact",
            name: "contact",
            component: Contact
        }
    ]
})