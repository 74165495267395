<template>
  <!-- As a link -->
  <b-navbar variant="secondary" type="dark" toggleable="lg">
    <b-container fluid="lg">
      <b-navbar-brand href="#" class="py-0">
        <b-row>
          <b-col class="pr-0">
            <img class="brand-logo" :src="'logo_jl.png' | img" height="55px" />
          </b-col>
          <b-col class="my-auto">
            <router-link to="/" style="text-decoration: none">
              <p class="nav-brand-text text-left my-auto">
                <span class="initials"><i>L</i></span
                ><span class="name-navbar">annes </span>
                <i>L</i>athouwers<br /><span
                  style="font-size: 1.1rem"
                  class="nav-brand-text"
                  >Elevating IT</span
                >
              </p></router-link
            >
          </b-col>
        </b-row>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" style="border: none">
        <template #default="{ expanded }">
          <b-img
            class="menu-logo-1"
            :class="{ expanded: expanded }"
            :src="'LOGO_letter_2.png' | img"
          ></b-img>
          <b-img
            class="menu-logo-2"
            :class="{ expanded: expanded }"
            :src="'LOGO_letter_1.png' | img"
          ></b-img>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/websites">Websites</b-nav-item>
          <b-nav-item to="/hosting">Software</b-nav-item>
          <!-- <b-nav-item to="/network">Netwerk/WiFi</b-nav-item>
          <b-nav-item to="/data-management">Data Management</b-nav-item>
          <b-nav-item to="/workstations" >Workstations</b-nav-item> -->
          <b-nav-item to="/infrastructure">IT Infrastructure</b-nav-item>
          <b-nav-item to="/contact" class="highlighted-button my-auto ml-1"
            >Contact</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style>
.initials {
  position: absolute;
  transform: rotateY(180deg);
  transition: transform 1s;

  /* background-color :red; */
}

.nav-brand-text {
  font-size: 1.3rem;
  line-height: 100%;
  margin: 0px;
  color: white;
}

.nav-link {
  color: white !important;
}

.nav-toggle:focus {
  border: none;
  outline: none;
}

.menu-logo-1 {
  width: 40px;
  transition: 0.3s;
  position: relative;
  left: 20px;
  top: 6px;
}
.menu-logo-2 {
  width: 40px;
  position: relative;
  top: -12px;
  left: -20px;
  transition: 0.3s;
}

.menu-logo-1.expanded {
  transform: translateY(-5px) rotateZ(60.9deg);
  transition: transform 0.3s;
}
.menu-logo-2.expanded {
  transform: translateY(13px) translateX(4px) rotateZ(-60.9deg) rotateX(180deg);
  transition: transform 0.3s;
}

.highlighted-button .nav-link {
  border: 1px solid white;
  border-radius: 0.3rem;
  padding: 4px 8px;

  transition: background-color 0.3s;
}
  .highlighted-button:hover .nav-link {
    background-color: white;
    color: #023e5c !important;
    transition: background-color 0.3s;
  }

  .name-navbar {
    margin-left: 10px;
  }

@media (max-width: 991px) {
  .brand-logo {
    height: 45px;
    margin: 8px;
  }

  .nav-link {
    color: white !important;
    font-size: 1.1rem;
    line-height: 95%;
    text-align: right;
    margin-right: 16px;
  }

  .navbar-collapse {
    background-color: #023e5c;
    /* margin-left: -16px;
    margin-right: -16px; */
  }

  .navbar-custom {
    background-color: green !important;
  }
  .navbar.navbar-dark.bg-secondary.navbar-expand-lg {
    padding: 0px;
  }

  .highlighted-button .nav-link {
    border: none;
      padding: 8px 8px;
  }

}

@media (max-width: 400px) {
  .brand-logo {
    height: 40px;
  }
  .nav-brand-text {
    font-size: 1rem;
  }

  .name-navbar {
    margin-left: 8px;
  }
}

@media (max-width: 330px) {
  .brand-logo {
    display: none;
  }

  .nav-brand-text {
    font-size: 1rem;
  }

  .navbar-toggler.collapsed,
  .navbar-toggler.not-collapsed {
    padding: 16px 0px;
  }
}
</style>