<template>
  <b-container class="px-0 " fluid>
    <b-row class="bg-info py-5 mx-0">
      <b-col class="px-0">
        <b-container>
          <b-row class="">
            <b-col cols lg="6" class="px-0 py-3 my-4 ml-auto mx-0">
              <b-img class="site-img-animated" :src="'site-kvv.png' | img" height="300"></b-img>
              <b-img class="site-img-animated" :src="'site-jle.png' | img" height="300"
                style="margin-left: -80px"></b-img>
              <b-img class="site-img-animated" :src="'site-jlo.png' | img" height="300"
                style="margin-left: -80px"></b-img>
            </b-col>
            <b-col cols lg="6" class="my-auto text-left mr-auto ">
              <h1 class="text-white">Websites & Email</h1>
              <hr>
              <p class="text-white">
                Your website is an important cornerstone of your business. It is your billboard to show the world the
                services
                and products you offer.<br><br>
                This is why a solid understanding of your business is essential for me. Only with that information can I
                make a
                website which properly represents you and your business.<br><br>
                Email addresses with your domain extension are also essential for any serious business. For this you can
                also
                count on me!
              </p>
              <b-btn color="primary" to="/contact">Get in touch</b-btn><br />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <b-row class="mx-0">
      <b-col class="px-0">
        <b-container>
          <b-row class="py-5">
            <!-- <b-col cols lg="3" class="ml-auto">
              <b-card footer-tag="footer" class="h-100">
            <b-card-text>
              <b-icon icon="terminal-fill" font-scale="5" variant="info"> </b-icon></b-card-text>
          </b-card>
            </b-col> -->
            <b-col cols lg="8" class="my-auto text-left mr-auto ">
              <h1 class=""><b-icon icon="terminal-fill" variant="info"> </b-icon> Web Applications </h1>
              <hr>
              <p class="">
                When your website needs to do more than just be beautiful.
                <br><br>

                I strive to solve your business' problems with custom built solutions.
                These solutions will keep you more organized and will save you precious time which you can spend doing the
                things you do best.
              </p>
              <b-btn color="primary" to="/contact">Ask your questions</b-btn><br />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Websites",
};
</script>

<style scoped>
.site-img-animated:hover {
  transform: scale(1.25);
}

.site-img-animated:hover~.site-img-animated {
  transform: perspective(400px) translateX(100px) rotateY(45deg);
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
}

.site-img-animated {
  transform: perspective(400px) rotateY(45deg);
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.site-img-animated-side {
  transform: translateZ(10px) scale(1.1375);
  transition: transform 0.5s;
}

.site-img-animated:hover~.site-img-animated-side {
  transform: perspective(400px) scale(1.1375) translateX(100px);
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
}
</style>