<template>
  <div id="app">
    <navbar></navbar>
    <b-container fluid id="main" style="min-height: 80vh;">
      <b-row>
        <b-col cols="12" class="px-0"><router-view></router-view></b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/shared/Navbar.vue";
import Footer from "@/components/shared/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;600&family=Titillium+Web:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;600&family=Rajdhani&display=swap');
#app {
  /* font-family: "Titillium Web", sans-serif; */
    font-family: "Exo 2", sans-serif;
        /* font-family: "Chakra Petch", sans-serif; */
  /* font-family: "Rajdhani", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
