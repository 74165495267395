<template>
  <b-container fluid class="bg-info text-white py-2">
    <b-container>
      <b-row class="text-left">
        <b-col cols lg="3" class="mx-auto  text-center pt-3">
        <b-img
          :src="'logo_jl.png' | img"
          height="125px"
          class="mx-2 logo"
        ></b-img>
        </b-col>
                <b-col cols lg="3" class="mx-auto py-3 small-centered ">
          Jannes Lathouwers<br /><br />
          BE0720 934 583<br /><br />
          Dekenstraat 30/0401 <br />
          3000 Leuven
        </b-col>
        <b-col cols lg="3" class="mx-auto py-3 small-centered ">
          <router-link to="/websites">Websites</router-link><br>
          <router-link to="/websites">Webapplications</router-link><br>
          <router-link to="/websites">Email</router-link><br>
          <router-link to="/hosting">On-premise software hosting</router-link><br>
          <router-link to="/hosting">Cloud software hosting</router-link><br>
          <router-link to="/infrastructure">Networks & WiFi</router-link><br>
          <router-link to="/infrastructure">Network storage</router-link><br>
          <router-link to="/infrastructure">Workstations</router-link><br>
          <router-link to="/contact">Contact</router-link><br>
        </b-col>

      </b-row>
    </b-container>
    <b-container>
    <b-row>
      <b-col class="py-3">
        Copyright Jannes Lathouwers © {{ year }}<br />

      </b-col>
    </b-row>
    </b-container>

  </b-container>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
a {
  color: white;
}

@media (max-width: 991px) {
  .logo {
    height: 90px;
  }
}

@media (max-width: 578px) {
  .small-centered {
      text-align: center;
  }
}
</style>