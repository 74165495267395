<template>
  <b-container fluid class="px-0">
    <b-container fluid class="bg-info py-md-5 py-4 px-0">
      <b-container>
        <b-row class="text-white">
          <b-col cols md="3" sm="12" class="my-2 my-auto">
            <div>
              <b-img
                :src="'Jannes.png' | img"
                fluid
                class="banner-image"
              ></b-img>
            </div>
          </b-col>
          <b-col cols md="9" sm="12" class="text-left bg-info ml-auto my-2">
            <h1 class="">Who am I?</h1>
            <hr />
            <p>
              Hello there!<br /><br />
              From a young age I had a passion and interest in using and
              building computer systems. During my technical education I worked
              as a cable technician to build up copper and fiber networks.
              Later, during my studies at university, I picked up website
              development and started making websites for customers.
              <br /><br />
              In 2021, I graduated from KU Leuven with a Master degree in
              Engineering for Computer Science. My specialization for this
              degree was Secure Software.
              <br /><br />
              Most of my professional experience comes from working as a
              software and systems engineer for a leading Aerospace company in
              Brussels (Belgium).
              <br /><br />
              It is a passion of mine to keep up with the constant modifications
              and developments in IT and to explore the possibilities that our
              modern technology allows. I am always interested in learning new
              technologies and applying these to make your life easier. If you
              have any questions about what I could do for your business, feel
              free to reach out using the form below!
              <br /><br />
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-container fluid class="bg-secondary px-0">
      <b-container class="text-white text-left py-5">
        <b-row class="px-0">
          <b-col>
            <h1 class="text-white">Get in contact</h1>
            <hr />
          </b-col>
        </b-row>
        <b-row style="min-height: 350px">
          <b-col cols md="6">
            <p>
              Jannes Lathouwers<br /><br />
              info@janneslathouwers.be<br /><br />
              BE0720 934 583<br /><br />
              Dekenstraat 30/0401 <br />
              3000 Leuven
            </p>
          </b-col>
          <b-col cols md="6">
            <b-form @submit="onFormSubmit()" v-if="captchaEnabled">
              <b-form-group label="Your name" label-for="name-input">
                <b-form-input
                  id="name-input"
                  placeholder="Your name"
                  required
                  v-model="name"
                  :state="validName"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group label="Your email" label-for="email-input">
                <b-form-input
                  id="email-input"
                  placeholder="Your Email"
                  type="email"
                  required
                  v-model="email"
                  :state="validEmail"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group label="Your message" label-for="message-input">
                <b-form-textarea
                  id="message-input"
                  placeholder="Your Message"
                  required
                  rows="5"
                  v-model="message"
                >
                </b-form-textarea>
              </b-form-group>
              <b-button
                variant="light"
                :disabled="!formValid"
                @click="sendContactMessage()"
                class="mb-3"
                >Send message</b-button
              ><b-alert v-model="showAlert" :variant="alertVariant">{{
                alertText
              }}</b-alert>
            </b-form>
            <span v-else>
              <h3>Contact form</h3>
              <b-button variant="light" v-b-modal.captcha-modal class="mb-3"
                >Show Contact Form
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-modal
      id="captcha-modal"
      ref="captcha-modal"
      title="Google ReCaptcha Privacy Notice"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <p class="my-2">
        Please note that enabling this contact form will enable Google ReCaptcha
        to verify that you are not a robot. This means that your data, which
        includes, but is not limited to:
      </p>
      <ul>
        <li>ReCIP address,</li>
        <li>Referrer URL,</li>
        <li>Operating system,</li>
        <li>Cookies,</li>
        <li>Mouse movements/keyboard strokes,</li>
        <li>Length of pauses between actions,</li>
        <li>Device settings (e.g. language or location),</li>
        <li>Google account information,</li>
      </ul>
      <p>
        will be sent to Google. By clicking "Consent and enable" you explicitly
        provide consent to the collection and processing of this data.
        You can find more information about how Google collect your data in
        their
        <a href="https://policies.google.com/privacy?hl=en" target="_blank"
          >Privacy Policy</a
        >.
      </p>
      <p>
        <b>If you do not wish to enable Google ReCaptcha to use this contact form</b>,
        you can reach me directly by sending an email to
        <a href="mailto:info@janneslathouwers.be">info@janneslathouwers.be</a>.
        I look forward to hearing from you either way.
      </p>
      <template slot="modal-footer">
        <b-button class="info" @click="closeModal()">Do not enable</b-button>
        <b-button variant="primary" @click="enableRecaptcha()"
          >Consent and enable</b-button
        >
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

export default {
  name: "Contact",
  data() {
    return {
      name: undefined,
      email: undefined,
      message: "",
      alertText: "",
      alertVariant: "success",
      showAlert: false,
      mailformat: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      captchaEnabled: false,
    };
  },
  created() {},
  methods: {
    async sendContactMessage() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("contact");
      (this.alertText = ""),
        (this.alertVariant = "success"),
        (this.showAlert = false);

      let data = {
        name: this.name,
        email: this.email,
        message: this.message,
        recaptcha: token,
      };
      axios
        .post("/api/handleform", data)
        .then(() => {
          this.alertText = "Message sent! Thank you for your message.";
          this.alertVariant = "success";
          this.showAlert = true;
        })
        .catch((err) => {
          console.log(err);
          this.alertText = "Something went wrong while sending your message.";
          this.alertVariant = "danger";
          this.showAlert = true;
        });
    },
    closeModal() {
      this.$refs["captcha-modal"].hide();
    },
    enableRecaptcha() {
      Vue.use(VueReCaptcha, {
        siteKey: "6LfarfEkAAAAAPA4zFM7ygLXMjLuquqfvpywEBl1",
      });
      this.captchaEnabled = true;
      this.$refs["captcha-modal"].hide();
    },
  },
  computed: {
    formValid() {
      return this.validName && this.validEmail && this.message;
    },
    validName() {
      return this.name == undefined ? null : this.name != "";
    },
    validEmail() {
      return this.email == undefined ? null : this.mailformat.test(this.email);
    },
  },
};
</script>

<style>
.banner-image {
  max-height: 400px;
}
</style>