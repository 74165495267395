<template>
  <b-container fluid class="px-0">
    <b-container fluid class="bg-info py-md-5 py-4 px-0">
      <b-container>
        <b-row class="text-white">
          <b-col cols md="6" sm="12" class="my-2 my-auto">
            <div class="banner-image">
              <b-img :src="'cloud.png' | img" fluid class="pt-2"></b-img>
            </div>
          </b-col>
          <b-col cols md="6" sm="12" class="text-left bg-info ml-auto my-2">
            <h1 class="">Software Hosting</h1>
            <hr />
            <p>
              Every business today needs software to perform its day-to-day
              tasks, optimize processes and workflows. Choosing the right
              software solutions is therefore essential. <br /><br />
              Whether your business is in need of online collaboration on
              documents, quick and easy videocalls, or a task board to keep
              organized, with the right software solution this can be a
              breeze.<br /><br />
              I offer installation and configuration of a variety of software
              products. Either hosted in the cloud or on your business' premise.
              The choice is yours.
            </p>
            <b-button variant="light" to="/contact">Get in contact</b-button>
          </b-col>

        </b-row>
      </b-container>
    </b-container>

    <b-container class="py-5">
      <b-row>
        <b-col cols md="4" sm="12" class="mx-auto my-2">
          <b-card footer-tag="footer" class="h-100">
            <b-card-text
              ><b-icon icon="house-fill" font-scale="5" variant="info"> </b-icon
              ><br />
              <h3 class="mt-3">On-Premise Hosting</h3>
              <hr class="w-50" />
              <p>
                For complete control and security you can choose to host
                software on your business' premises.
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col cols md="4" sm="12" class="mx-auto my-2">
          <b-card class="h-100">
            <b-card-text>
              <b-icon icon="clouds-fill" font-scale="5" variant="info"> </b-icon
              ><br />
              <h3 class="mt-3">Cloud Hosting</h3>
              <hr class="w-50" />
              <p>
                If you don't have the capacities to host the
                software locally then hosting it in the cloud will be the perfect solution for your
                business.
              </p></b-card-text
            >
          </b-card>
        </b-col>
        <b-col cols md="4" sm="12" class="mx-auto my-2">
          <b-card footer-tag="footer" class="h-100">
            <b-card-text>
              <b-icon icon="disc-fill" font-scale="5" variant="info"> </b-icon
              ><br />
              <h3 class="mt-3">Open Source Software</h3>
              <hr class="w-50" />
              <p>
                My preference is to use Free and Open Source Software (FOSS) solutions. 
                This way we can prevent unecessary costs for your business.
              </p></b-card-text
            >
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "Hosting",
};
</script>

<style>
.banner-image {
  background-color: white;
  border-radius: 1rem;
}

hr {
  border: 1px solid grey;
}
</style>