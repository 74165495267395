<template>
  <b-container class="px-0" fluid>
    <b-row class="py-5 mx-0  bg-info text-white ">
      <b-col class="px-0">
        <b-container>
          <b-row class="">
            <b-col cols lg="3" md="" class="my-auto py-2">
              <b-img :src="'webhosting.png' | img" fluid class="banner-image p-4"></b-img>
            </b-col>
            <b-col cols lg="6" class="my-auto text-left mr-auto my-2">
              <h1 class="">IT Infrastructure</h1>
              <hr>
              <p class="">
                A good IT infrastructure is essential for any business these days. I can provide support for setting up
                secure networks, WiFi, networked storage solutions and workstations. <br><br>
              </p>
              <b-btn color="primary" to="/contact">Get in touch</b-btn><br />
            </b-col>
          </b-row>

        </b-container>
      </b-col>
    </b-row>
    <b-row class="mx-0 py-5">
      <b-col>
        <b-container>
          <b-row>
            <b-col cols md="4" sm="12" class="mx-auto my-2">
              <b-card footer-tag="footer" class="h-100">
                <b-card-text><b-icon icon="wifi" font-scale="5" variant="info"> </b-icon><br />
                  <h3 class="mt-3">Networks & WiFi</h3>
                  <hr class="w-50" />
                  <p>
                    Secure and reliable company networks and WiFi solutions to keep you connected.
                  </p>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols md="4" sm="12" class="mx-auto my-2">
              <b-card class="h-100">
                <b-card-text>
                  <b-icon icon="hdd-rack-fill" font-scale="5" variant="info"> </b-icon><br />
                  <h3 class="mt-3">Network storage</h3>
                  <hr class="w-50" />
                  <p>
                    High performance and resilient file storage. Keep your data safe and accessible.
                  </p>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols md="4" sm="12" class="mx-auto my-2">
              <b-card footer-tag="footer" class="h-100">
                <b-card-text>
                  <b-icon icon="display" font-scale="5" variant="info"> </b-icon><br />
                  <h3 class="mt-3">Workstations</h3>
                  <hr class="w-50" />
                  <p>
                    High performance workstations to accelerate your workflow and save your precious time.
                  </p>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
  name: "Infrastructure"
}
</script>

<style>
.banner-image {
  background-color: white;
  border-radius: 1rem;
}
</style>