import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "@/assets/theme.scss";
import router from './router';
import VueRouter from "vue-router";
// import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)


Vue.filter('img', function (value) {
  return require('@/assets/img/' + value)
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
